import localStorage from "@/utils/localStorage";

export function getUserInfo(key) {
  if (key) return localStorage.get(key);

  return {
    authcode: localStorage.get("authcode"),
    phoneid: localStorage.get("phoneid"),
    uid: localStorage.get("uid"),
    username: localStorage.get("username"), // 企业成员名称
    userrole: localStorage.get("userrole"), // 分配的角色
    corpid: localStorage.get("corpid"), // 企业Id
    nickname: localStorage.get("nickname"),
    imgurl: localStorage.get("imgurl"),
  };
}

export function getAppInfo() {
  return {
    appName: "旅游产品",
  };
}
