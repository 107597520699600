// native与js的通信格式遵守JSON-RPC 2.0

function connect(cb) {
  if (window.NativeAPI && window.NativeAPI.sendToNative) {
    cb();
  } else {
    document.addEventListener("WebViewJavascriptBridgeReady", () => {
      if (window.NativeAPI && window.NativeAPI.sendToNative) {
        cb();
      }
    });
  }
}

let napi = null;

connect(() => {
  window.NativeAPI.sendToJavaScript = onNativeMessageReceived;
  napi = window.NativeAPI;
});

// --------------------------------------

let message_id = 1;

// js调用native方法回调函数: id -> function
const callback_set = {};

// (自定义的)native调用js的方法映射: name -> function
// 类似于监听客户端发来的事件，所有事件列表请参考客户端文档
const custom_js_methods = {};

// (默认的)native调用js的方法映射: name -> function
const DEFAULT_JS_METHODS = {
  // back，表示点击物理返回
  // 如果不写这个方法，或者返回preventDefaul:true，可以阻止浏览器回退
  back: () => ({ preventDefault: false }),

  // resume，表示是从其他webview或weex页面返回回来
  // 需要注意的是iOS上APP从后台进入前台时也会触发resume，但此时的参数是undefined，
  // 所以用这个回调的参数之前要检查一下。
  resume: (params) => {
    if (process.env.NODE_ENV !== "production") {
      if (params && params.nextUrl) {
        console.log("resume from:", params.nextUrl);
      }
    }
  },
};

// 注册某个事件的自定义方法
//
// NOTE:
// 1. 因为客户端依赖这些方法的返回值，所以后注册的会覆盖之前的
// 2. 如果需要取消自定义的方法，可以传fn为false
const registerMethod = (name, fn) => {
  custom_js_methods[name] = fn;
};

const getMethodByName = (name) => {
  return custom_js_methods[name] || DEFAULT_JS_METHODS[name];
};

// --------------------------------------

const noop = () => {};

// js调用native方法，结果通过onNativeMessageReceived返回
function invoke(method, params = null, callback = noop) {
  if (!napi) {
    callback({
      code: -32100,
      message: "nativeapi not ready",
      data: {
        method,
        params,
      },
    });
  } else {
    let msg = {
      jsonrpc: "2.0",
      method,
      params,
      id: message_id,
    };
    message_id++;

    callback_set[msg.id] = (error, result) => {
      callback(error, result);
    };

    napi.sendToNative(JSON.stringify(msg));
  }
}

// 收到native发来的消息
// 消息分两种，一是返回结果，二是调用js的方法
function onNativeMessageReceived(message) {
  try {
    message = JSON.parse(message);
  } catch (e) {
    throw new Error("NativeAPI response syntax error: " + message);
  }

  if (message.method) {
    // 客户端调用JS方法
    handleMethodCall(message);
  } else if (message.id) {
    // 客户端返回结果
    handleMessageResponse(message);
  } else {
    throw new Error("NativeAPI error message: " + message);
  }
}

// native调用js方法
function handleMethodCall(message) {
  let method = getMethodByName(message.method);

  if (typeof method === "function") {
    const result = method(message.params);
    napi.sendToNative(
      JSON.stringify({
        jsonrpc: "2.0",
        result,
        id: message.id,
      })
    );
  }
}

// js调用native的结果
function handleMessageResponse(message) {
  const callback = callback_set[message.id];
  callback_set[message.id] = undefined;

  if (!callback) {
    throw new Error(`NativeAPI missing callback for: ${message}`);
  }

  callback(message.error || null, message.result);
}

export default {
  invoke,
  on: registerMethod,
  ready: connect,
};
