export function cloneConfig(config) {
  const r = {};
  [
    "baseURL",
    "url",
    "method",
    "withCredentials",
    "timeout",
    "sidFields",
    "hlp",
  ].forEach((prop) => {
    if (config[prop] !== undefined) {
      r[prop] = config[prop];
    }
  });
  r.headers = {
    ...config.headers,
  };
  r.data = {
    ...config.data,
  };
  return r;
}
