import auth from "./auth";
import { Base64 } from "js-base64";

// authcode过期时间默认30分钟
const DEFAULT_AUTHCODE_EXPIRE_TIME = 1800;

let cache_ = null;

// 设置缓存为新的promise
// promise成功的值为`{authcode}`
function setCache(p) {
  cache_ = p.then(({ authcode, refreshcode }) => {
    cache_.value = "Basic " + Base64.encode(authcode);

    const expire = (DEFAULT_AUTHCODE_EXPIRE_TIME - 60) * 1000;
    cache_.expire_at = new Date().getTime() + expire;

    return {
      authcode,
      refreshcode,
      Authorization: cache_.value,
    };
  });

  cache_.catch(() => {
    cache_.fail = true;
  });
}

function checkCache() {
  if (cache_ && cache_.expire_at <= new Date().getTime()) {
    cache_ = null;
  }
}

// 获取token
function get() {
  checkCache();
  if (!cache_ || cache_.fail) {
    setCache(auth.getToken());
  }

  return cache_;
}

// 获取token
// 注意与get()的区别
function getOnce() {
  checkCache();
  if (!cache_) {
    setCache(auth.getToken());
  }

  return cache_;
}

// 为了避免多个请求都过期都要求刷新，这里会比较传入的无效token与缓存的是否一致
function refresh({ invalidate } = {}) {
  if (cache_ && cache_.value && invalidate === cache_.value) {
    console.info(`refresh token @${new Date()}`);
    setCache(auth.getToken({ refresh: true }));
  }
}

function login(...args) {
  cache_ = null;
  return auth.login(...args);
}

function logout() {
  cache_ = null;
  return auth.logout();
}

// [模板] 查询是否已登录并主动去登录。
// 实际应用中可能需要显示Loading，但注意只能在get()显示浮层，不要在login()过程中显示。
function ensure(...args) {
  return get().catch((e) => {
    if (e.name === "AuthError") {
      return login(...args);
    } else {
      throw e;
    }
  });
}

export default {
  get,
  getOnce,
  refresh,
  login,
  logout,
  ensure,
};
