<template>
  <div class="login-sms">
    <div class="a-page-head">
      <div class="a-page-title">登录/注册</div>
    </div>

    <c-input
      label="手机号码"
      placeholder="请输入手机号"
      v-model="phone"
    ></c-input>

    <c-input label="验证码" placeholder="请输入验证码" v-model="code" noclear>
      <div v-if="seconds > 0" class="a-text-16 a-gray">
        {{ seconds }}秒后重试
      </div>
      <div v-else class="a-text-16 a-blue" @click="onSend">发送验证码</div>
    </c-input>

    <div class="a-btn-line" style="margin-top: 20px">
      <div class="a-btn" @click="onSubmit">登录</div>
    </div>

    <div class="agreement">
      <span
        >若您的微信号/手机号未注册，将为您自动注册。注册或登录即代表您已阅读并同意</span
      >
      <span class="link" @click="viewAgreement">《服务协议》</span>
      <span>和</span>
      <span class="link" @click="viewPrivacy">《隐私条款》</span>
    </div>
  </div>
</template>

<script>
import auth from "@/lib/auth/auth";
import request from "@/lib/request";
import env from "@/utils/env";
import validate from "@/utils/validate";
import safeurl from "@/utils/safeurl";

export default {
  data() {
    return {
      phone: "",
      code: "",
      seconds: 0,
    };
  },

  beforeDestroy() {
    clearTimeout(this._timer);
  },

  methods: {
    alert(message) {
      this.$notify({
        message,
      });
    },

    countdown() {
      const seconds = ~~((this._till - new Date().getTime()) / 1000);

      if (seconds > 0) {
        this.seconds = seconds;
        this._timer = setTimeout(() => {
          this.countdown();
        }, 1000);
      } else {
        this.seconds = 0;
      }
    },

    onSend() {
      if (this.seconds > 0) {
        return;
      }

      if (!validate.phone(this.phone)) {
        this.alert("请输入正确的手机号");
        return;
      }

      clearTimeout(this._timer);

      this.$withLoading(request.gateway)({
        url: "/gateway/api/user",
        auth: "no",
        data: {
          pid: 102303,
          phone: this.phone,
          type: env.hlsource === "sinopec" ? "kssl_0" : "",
        },
        sidFields: ["uid", "pid", "phone"],
      }).then(
        () => {
          this._till = new Date().getTime() + 60 * 1000;
          this.countdown();
        },
        (e) => {
          this.alert(e.message || "抱歉，我们遇到一点问题，请您稍后再试。");
        }
      );
    },

    onSubmit() {
      if (!validate.phone(this.phone)) {
        this.alert("请输入正确的手机号");
        return;
      }

      if (!this.code) {
        this.alert("请输入验证码");
        return;
      }

      this.$withLoading(request.gateway)({
        url: "/gateway/user/loginverify",
        method: "POST",
        data: {
          pid: 41021,
          phone: this.phone,
          code: this.code,
        },
        sidFields: ["uid", "pid", "phone", "systemtime"],
      }).then(
        (r) => {
          const data = r.res?.bd;
          if (data) {
            auth.setToken(data);
            this.done();
          } else {
            this.alert("数据错误");
          }
        },
        (e) => {
          this.alert(e.message || "网络服务异常，请稍候再试");
        }
      );
    },

    done() {
      const { ru } = this.$route.query || {};
      const { rp, onSuccess } = this.$route.params || {};

      // 如果指定了登陆成功后跳转的地址，且已经加载了对应模块，则replaceState。
      // 比如通过push或replace去一个要求登陆的页面。
      if (rp) {
        const matched = this.$router.getMatchedComponents(rp);

        if (matched && matched.length > 0) {
          this.$router.replace(rp);
          return;
        }
      }

      // 如果传了成功回调且没有刷新登陆页面，则调用回调。
      // 比如直接调用login()
      if (onSuccess) {
        onSuccess();
        // this.$router.back();
        this.$page.back()
      } else {
        if (ru) {
          location.replace(safeurl(ru));
        } else {
          // this.$router.back();
          this.$page.back()
        }
      }
    },

    viewAgreement() {
      window.location =
        "https://dl.rsscc.cn/faq/policyagreement/hbgjserviceagreement.html";
    },

    viewPrivacy() {
      window.location =
        "https://dl.rsscc.cn/faq/policyagreement/hbgjprivacypolicy.html";
    },
  },
};
</script>

<style lang="scss" scoped>
.login-sms {
  height: 100%;
}
.other-login {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.other-login-item {
  width: 80px;
  text-align: center;
}
.other-login-icon {
  width: 40px;
  height: 40px;
}
.other-login-text {
  font-size: 12px;
  color: #333;
  word-break: break-all;
}

.agreement {
  padding: 28px 16px;
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: rgba(52, 56, 75, 0.5);
  line-height: 17px;

  .link {
    color: #0078ff;
  }
}
</style>
