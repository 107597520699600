import localStorage from "@/utils/localStorage";
// eslint-disable-next-line no-unused-vars
import env from "@/utils/env";
import "./loadStyles";
import "./loadRoutes";
import "./loadComponents";
import "./uid";

const APP_VERSION = 20201218;

function init_localstorage() {
  const STORAGE_VERSION = Number(localStorage.get("APP_VERSION") || 0);
  if (!(STORAGE_VERSION >= APP_VERSION)) {
    localStorage.clear();
    localStorage.set("APP_VERSION", APP_VERSION);
  }
}

init_localstorage();

// if (process.env.NODE_ENV !== "production") {
  // require("eruda").init();

  // const root = document.body;

  // const s1 = document.createElement("script");
  // s1.src = "https://cdn.jsdelivr.net/npm/eruda";

  // const s2 = document.createElement("script");
  // s2.innerHTML = `setTimeout(() => eruda.init(), 2000)`;

  // root.appendChild(s1);
  // root.appendChild(s2);
// }
