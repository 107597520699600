<template>
  <div class="a-input-block">
    <div class="a-input-head">
      <div class="a-input-label">{{ label }}</div>
      <slot name="action"></slot>
    </div>
    <div class="a-input-body">
      <slot name="start"></slot>
      <input
        class="a-input"
        v-bind="$props"
        @input="$emit('input', $event.target.value)"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
      />
      <div
        :class="{ 'a-invisible': noclear || !value }"
        class="a-input-icon-clear"
        @click="onClickClear"
      ></div>
      <slot></slot>
    </div>
    <slot name="end"></slot>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    name: String,
    form: String,
    label: String,
    type: String,
    placeholder: String,
    value: [String, Number],
    readonly: Boolean,
    disabled: Boolean,
    noclear: Boolean,
  },

  methods: {
    onClickClear() {
      if (!this.noclear && this.value) {
        this.$emit("input", "");
      }
    },
  },
};
</script>
