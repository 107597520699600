import router from "@/router";

const routes = [
  {
    path: "/home",
    component: () => import("./index.vue"),
    meta: {
      title: window.location.hostname == "www.haohuimai.com" || window.location.hostname == "haohuimai.com"?'活力天汇景点票务':"旅行精品",
    },
  },
];

router.addRoutes(routes);

export default router;
