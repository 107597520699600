// 伙力商旅
// source: 'webapphlsl',
// appname: 'hlsl',
// platform: 'web'

export default {
  hlid: "hlL3UGIu",

  // 标题
  pageTitle: "中石化",

  // // 客服电话
  // customerServicePhone: "4008005753",
};
