import qs from "qs";
import env from "@/utils/env";

const _keys = ["hlsource", "analyseSourceEntry", "tmc"];

function getParams() {
  return _keys.reduce((r, key) => {
    if (env[key]) {
      r[key] = env[key];
    }
    return r;
  }, {});
}

function patchURL(url) {
  const qi = url.indexOf("?");
  const p = qi >= 0 ? url.slice(0, qi) : url;
  const q = qi >= 0 ? qs.parse(url.slice(qi + 1)) : {};
  const s = qs.stringify({
    ...getParams(),
    ...q,
  });
  return s ? p + "?" + s : p;
}

export default {
  get: getParams,
  patchURL: patchURL,
};
