import addH5Params from "./addH5Params";
import addGatewayParams from "./addGatewayParams";
import addV3Params from "./addV3Params";
import addSid from "./addSid";
import { cloneConfig } from "./utils";

export default {
  h5,
  gateway,
  hotel,
  train,
  ticket, // 机票4位pid接口
  pay,
  defray,
};

function h5(config) {
  const input = cloneConfig(config);
  if (!input.baseURL) {
    input.baseURL = process.env.VUE_APP_BASE_URL_H5;
  }
  return addH5Params(input);
}

function gateway(config) {
  const input = cloneConfig(config);
  if (!input.baseURL) {
    input.baseURL = process.env.VUE_APP_BASE_URL_COMMON;
  }
  return gateway_template(input);
}

function hotel(config) {
  const input = cloneConfig(config);
  input.baseURL = input.baseURL || process.env.VUE_APP_BASE_URL_COMMON;
  input.url = input.url || "/gateway/corp/tmcapp";
  input.method = input.method || "POST";
  input.sidFields = input.sidFields || ["uid", "pid"];
  input.data = {
    ...input.data,
    tmc: 1,
  };
  return gateway_template(input);
}

function train(config) {
  const input = cloneConfig(config);
  if (!input.baseURL) {
    input.baseURL = process.env.VUE_APP_BASE_URL_GAOTIE;
  }
  return gateway_template(input);
}

function ticket(config) {
  const input = cloneConfig(config);
  if (!input.baseURL) {
    input.baseURL = process.env.VUE_APP_BASE_URL_COMMON;
  }
  return v3_template(input);
}

function pay(config) {
  const input = cloneConfig(config);
  if (!input.baseURL) {
    input.baseURL = process.env.VUE_APP_BASE_URL_PAY;
  }
  input.data = {
    ...input.data,
    platform: "web", // 固定web，不能改
    beta: "0",
  };
  return v3_template(input);
}

function defray(config) {
  const input = cloneConfig(config);
  if (!input.baseURL) {
    input.baseURL = process.env.VUE_APP_BASE_URL_DEFRAY;
  }
  // 开发模式配置了跨域（vue.config.js），不要设置 baseURL
  if (process.env.NODE_ENV === "development") {
    input.baseURL = null;
  }
  return v3_template(input);
}

// ---------
function gateway_template(opt) {
  opt = addGatewayParams(opt);
  return addSid(opt);
}

function v3_template(opt) {
  opt = addV3Params(opt);
  return addSid.v3(opt);
}
