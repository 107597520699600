import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Boot
import "./boot";

(function (w, d) {
  var root = d.documentElement;
  function setRootFontSize() {
    var w = root.clientWidth;
    var x = w / 18.75;
    root.style.fontSize = x + "px";
  }

  (function setBodyFontSize() {
    if (d.body) {
      d.body.style.fontSize = "16px";
    } else {
      d.addEventListener("DOMContentLoaded", setBodyFontSize);
    }
  })();

  setRootFontSize();
  w.addEventListener("resize", function () {
    setRootFontSize();
  });
  w.addEventListener("pageshow", function () {
    setRootFontSize();
  });
})(window, document);

(function (e, t, n, i, s, a, c) {
  e[n] =
    e[n] ||
    function () {
      (e[n].q = e[n].q || []).push(arguments);
    };
  a = t.createElement(i);
  c = t.getElementsByTagName(i)[0];
  a.async = true;
  a.src = s;
  c.parentNode.insertBefore(a, c);
})(window, document, "ha", "script", "https://cdn.133.cn/ticket/h5/lib/hl-analytics-1.11.1.min.js");


// 设置网关公共参数，hlid、uid、uuid、projectver、modulecver 等
// ha("set", "hlid", partnerConfig.hlid || process.env.VUE_APP_API_HLID);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
