<template>
  <div class="p" v-if="content" v-html="content"></div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
    },
  },
};
</script>

<style scoped>
.p {
  white-space: pre-wrap;
}
</style>
