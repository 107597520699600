// 伙力商旅
// source: 'webapphlsl',
// appname: 'hlsl',
// platform: 'web'

export default {
    hlid: "hlL9AIoZ",
  
    // 标题
    pageTitle: "京东方",
  
    // // 客服电话
    // customerServicePhone: "4008005753",
  };
  