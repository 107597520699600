// 凯撒商旅APP
// source: 'webappkssl',
// appname: 'kssl',
// platform: 'web'

export default {
  hlid: "hlK79HMj",

  // 标题
  pageTitle: "凯撒商旅",

  // 客服电话
  customerServicePhone: "4008005753",
};
