import router from "@/router";

const routes = [
  { // 综合页
    name: 'common-list',
    path: '/common-list',
    component: () => import('./common-list.vue'),
  },
  { // 门票列表
    name: 'list',
    path: '/list',
    component: () => import('./list.vue'),
  },
  { // 跟团游列表
    name: 'tour-group',
    path: '/tour-group',
    component: () => import('./tour-group.vue'),
  },
  { // 酒景列表
    name: 'hotel-scenic-list',
    path: '/hotel-scenic-list',
    component: () => import('./hotel-scenic-list')
  }
]

router.addRoutes(routes);

export default router;
