import router from "@/router";
const routes = [
  //   {
  //     // 订单合同页
  //     name: "order-constract",
  //     path: "/order-constract",
  //     component: () => import("./constract.vue"),
  //   },
  //   {
  //     // 订单合同页
  //     name: "tour-book",
  //     path: "/tour-book",
  //     component: () => import("./tour-book.vue"),
  //   },
  {
    // 2023 周杰伦演唱会活动页面
    name: "concert-activity",
    path: "/concert-activity",
    component: () => import("./concert-activity.vue"),
  },
];

router.addRoutes(routes);

export default router;
