import bridge from "./huoli-bridge";
import qs from "./querystring";

const ready_ = new Promise((resolve) => {
  bridge.ready(() => {
    resolve();
  });
});

function ready() {
  return ready_;
}

function invoke(method, params) {
  return new Promise((resolve, reject) => {
    if (window.huoli && window.huoli.native === "disabled") {
      return reject(new Error("invoke native api fail: disabled"));
    } else {
      return bridge.invoke(method, params, (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    }
  });
}

export default {
  ready: ready,
  invoke: invoke,
  on: bridge.on,

  // 关闭 native 弹窗，关闭 openOuterUrl 打开的 webview
  closeOuterUrl(paramObj) {
    let url = "weixinhbgj://start?type=navigationback";
    if (paramObj) {
      url += `&${qs.stringify(paramObj)}`;
    }

    window.location.href = url;
  },

  // native 中打开站外（非航班）url
  openOuterUrl(url) {
    window.location.href = `weixinhbgj://start?type=embedweb&url=${encodeURIComponent(
      url
    )}`;
  },
};
