import urlParse from "url-parse";

const defaultUrl = "https://h5.133.cn/hltrip/home";

const allowedHosts = ["wtest.133.cn", "h5.133.cn", "jp.rsscc.com"];

export default function (url) {
  const r = urlParse(url, {});
  if (allowedHosts.indexOf(r.host) >= 0) {
    return url;
  }
  return defaultUrl;
}
