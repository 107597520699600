import MemoryStorage from "./memoryStorage";

const sessionStorage = window.sessionStorage || new MemoryStorage();

export default {
  set(key, val) {
    return sessionStorage.setItem(key, JSON.stringify(val));
  },
  get(key) {
    const val = sessionStorage.getItem(key);
    if (val === null) return null;
    try {
      return JSON.parse(val);
    } catch (e) {
      sessionStorage.removeItem(key);
      return null;
    }
  },
  remove(key) {
    return sessionStorage.removeItem(key);
  },
  clear() {
    return sessionStorage.clear();
  },
};
