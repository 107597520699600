import localStorage from "@/utils/localStorage";
import router from "@/router";

export default {
  login({ passive, target_url, rp } = {}) {
    // 如果登录成功后要跳转到的地址就是当前地址，并且是被动模式，则replace。
    const currentUrl = window.location.href;
    const isSameUrl = target_url === currentUrl;
    const method = isSameUrl && passive ? "replace" : "push";

    return new Promise((resolve) => {
      return router[method]({
        name: "login",
        query: {
          ru: target_url || currentUrl,
        },
        params: {
          rp: rp || "",
          onSuccess: () => {
            resolve();
          },
        },
      });
    });
  },

  logout({ target_url, rp } = {}) {
    localStorage.clear();
    const currentUrl = window.location.href;
    const isSameUrl = target_url === currentUrl;
    const method = isSameUrl ? "replace" : "push";

    return new Promise((resolve) => {
      return router[method]({
        name: "login",
        query: {
          ru: target_url || currentUrl,
        },
        params: {
          rp: rp || "",
          onSuccess: () => {
            resolve();
          },
        },
      });
    });
  },

  getToken({ refresh } = {}) {
    const authcode = localStorage.get("authcode");

    // 强制要刷新，则重新登录
    if (refresh) {
      localStorage.remove("authcode");
      return Promise.reject({
        name: "AuthError",
        message: "请登录后再操作",
      });
    } else if (authcode) {
      return Promise.resolve({ authcode });
    } else {
      return Promise.reject({
        name: "AuthError",
        message: "登录信息失效，请登录后再操作!",
      });
    }
  },

  setToken(data) {
    localStorage.set("authcode", data.authcode);
    localStorage.set("refreshcode", data.refreshcode);
  },
};
