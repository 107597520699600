// 伙力商旅
// source: 'webapphlsl',
// appname: 'hlsl',
// platform: 'web'

export default {
  hlid: "hlK79HRI",

  // 标题
  pageTitle: "伙力商旅",

  // 客服电话
  customerServicePhone: "4008005753",
};
