import router from "@/router";
import PageNotFound from "../views/404.vue";

// 业务路由
// 首页
import "@/projects/home/router";
// 登录
import "@/projects/login/router";
// 列表页
import "@/projects/list/router";
//详情页
import "@/projects/detail/router";
// 支撑页面
import "@/projects/support/router";


// 404页面要放在最后一个加载
router.addRoutes([
  {
    path: "*",
    component: PageNotFound,
  },
]);

export default router;
