import router from "@/router";
import login from "./login.vue";

const routes = [
  {
    name: "login",
    path: "/login",
    component: login,
  },
];

router.addRoutes(routes);

export default router;
