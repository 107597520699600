import hlsl from "./hlsl";
import kssl from "./kssl";
import dfyh from "./dfyh";
import pax from "./pax";
import boe from "./boe";
import sinopec from "./sinopec";
import cnpc from "./cnpc";
export default {
  hlsl,
  kssl,
  dfyh,
  pax,
  boe,
  sinopec,
  cnpc,
};
