import router from "@/router";

const routes = [
  {
    // 门票详情页面
    name: "scenic-detail",
    path: "/scenic-detail",
    component: () => import("./scenic-detail/scenic-detail.vue"),
  },
  {
    // 景点详情简介页面
    name: "scenic-introduction",
    path: "/scenic-introduction",
    component: () => import("./scenic-detail/scenic-introduction.vue"),
  },
  {
    // 跟团游详情页面
    name: "tour-group-detail",
    path: "/tour-group-detail",
    component: () => import("./tour-group-detail/tour-group-detail.vue"),
  },
  {
    // 酒景详情页面
    name: "hotel-scenic",
    path: "/hotel-scenic",
    component: () => import("./hotel-scenic/hotel-scenic-detail.vue"),
  },
  {
    // 酒店详情简介页面
    name: "hotel-introduction",
    path: "/hotel-introduction",
    component: () => import("./hotel-scenic/hotel-introduction.vue"),
  },
  {
    //图片列表页
    name: "photoList",
    path: "/photoList",
    component: () => import("./components/photoList.vue"),
  },
];

router.addRoutes(routes);

export default router;
