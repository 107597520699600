import env from "@/utils/env";

export default function (opt) {
  opt.headers = {
    "content-type": "application/json",
    ...opt.headers,
  };
  opt.data = {
    // iOS微信会缓存请求响应，包括POST，所以在每个请求上添加一个时间戳
    t: new Date().getTime(),
    analyseSourceEntry: env.analyseSourceEntry || "",
    ...opt.data,
  };
  opt.withCredentials = true;
  return opt;
}
