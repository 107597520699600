/* eslint-disable */
import cookie from 'js-cookie';
import qs from './querystring'; // 不用qs库的原因见./querystring
import sessionStorage from './sessionStorage';

const UA = window.navigator.userAgent;
const query = qs.parse(window.location.search);
const os = getOs();

const env = {
  userChannel: '',
  partner: '',
  hlsource: '',
  device: getBrowser(),
  analyseSourceEntry: '',
  tmc: '',

  os: os.name,
  osVersion: os.version,
  appName: getAppName(),

  weixinJsReady: weixinJsReady,
  isWeixin: /micromessenger/i.test(UA),
  isWeixinMiniProgram: null, // 在weixinJsReady回调内判断
  isNative: null,

  isMiniProgram: isMiniProgram(),
  miniProgramApi: getMiniProgramApi()
};

export default env;

function _init() {
  let hlsource = query.hlsource;
  if (hlsource) {
    sessionStorage.set('hlsource', hlsource);
  } else {
    hlsource = sessionStorage.get('hlsource');
  }
  env.partner = env.hlsource = hlsource || '';

  let analyseSourceEntry = query.analyseSourceEntry;
  if (analyseSourceEntry) {
    sessionStorage.set('analyseSourceEntry', analyseSourceEntry);
  } else {
    analyseSourceEntry = sessionStorage.get('analyseSourceEntry');
  }
  env.analyseSourceEntry = analyseSourceEntry;
  env.tmc = query.tmc;

  let userChannel = query.userChannel;
  if (userChannel) {
    sessionStorage.set('userChannel', userChannel);
  } else {
    userChannel = sessionStorage.get('userChannel');
  }
  env.userChannel = userChannel;
}

_init();

function getOs() {
  let ua = window.navigator.userAgent;
  let name = 'unknown';
  let version = '-';

  if (/(iphone|ipad|ipod)/i.test(ua)) {
    name = 'ios';
    const r = /OS ([\d_]+)/.exec(ua);
    if (r) {
      version = r[1].replace(/_/g, '.');
    }
  } else {
    const r = /Android ([\d.]+)/.exec(ua);
    if (r) {
      name = 'android';
      version = r[1];
    }
  }

  return {
    name,
    version
  };
}

// 获取当前运行平台或APP
if (env.isWeixin) {
  weixinJsReady(() => {
    env.isWeixinMiniProgram =
      /miniprogram/i.test(UA) || window.__wxjs_environment === 'miniprogram';
  });
} else {
  // iOS 9.1用不了NativeAPI
  webviewJsReady(() => {
    const whiteList = ['rsscc.com', '133.cn', 'huoli.com', '192.168'];
    env.isNative = whiteList.some(d => location.host.indexOf(d) >= 0);
    // 在值机中用partner判断展示自动选座等内容 故保留partner以作判断
    let appName = getAppName();
    if (['kssl', 'hlsl'].indexOf(appName) < 0) {
      env.partner = '';
    }
  });
}

function getAppName() {
  // 这是获取appName的最直接方法，不需要调用nativeAPI等待异步结果
  const appName = cookie.get('appName');
  if (/hbgj/.test(appName)) {
    return 'hbgj';
  } else if (/gtgj/.test(appName)) {
    return 'gtgj';
  } else if (/hlcar/.test(appName)) {
    return 'hlcar';
  } else if (/hlsl/.test(appName)) {
    return 'hlsl';
  } else if (/kssl/.test(appName)) {
    return 'kssl';
  } else {
    return '';
  }
}

function isMiniProgram() {
  if (/miniprogram/i.test(UA) || window.__wxjs_environment === 'miniprogram') {
    return true;
  } else if (/aliapp/i.test(UA)) {
    return true;
  } else if (/swan\//.test(UA) || /^webswan-/.test(window.name)) {
    return true;
  } else {
    return null;
  }
}

function getMiniProgramApi() {
  if (/miniprogram/i.test(UA) || window.__wxjs_environment === 'miniprogram') {
    // 微信小程序 https://developers.weixin.qq.com/miniprogram/dev/component/web-view.html
    /* eslint-disable */
    return wx.miniProgram;
    /* eslint-enable */
  } else if (/aliapp/i.test(UA)) {
    // 支付宝小程序 https://opendocs.alipay.com/mini/component/web-view
    /* eslint-disable */
    return my;
    /* eslint-enable */
  } else if (/swan\//.test(UA) || /^webswan-/.test(window.name)) {
    // 百度小程序 https://smartprogram.baidu.com/docs/develop/component/open_web-view/
    /* eslint-disable */
    return swan.webView;
    /* eslint-enable */
  } else {
    return {};
  }
}

// 航班管家/高铁管家JS Bridge
function webviewJsReady(cb) {
  if (window.NativeAPI && window.NativeAPI.sendToNative) {
    cb();
  } else {
    document.addEventListener(
      "WebViewJavaScriptBridgeReady",
      () => {
        if (window.NativeAPI && window.NativeAPI.sendToNative) {
          cb();
        }
      },
      false
    );
  }
}

// 微信JS Bridge
function weixinJsReady(cb) {
  if (window.WeixinJSBridge && window.WeixinJSBridge.invoke) {
    cb();
  } else {
    document.addEventListener("WeixinJSBridgeReady", cb, false);
  }
}

function getBrowser() {
  const browser = {};
  const userAgent = navigator.userAgent.toLowerCase();
  let s;
  // eslint-disable-next-line no-cond-assign
  (s = userAgent.match(/msie ([\d.]+)/))
    ? (browser.ie = s[1])
    : // eslint-disable-next-line no-cond-assign
    (s = userAgent.match(/firefox\/([\d.]+)/))
    ? (browser.firefox = s[1])
    : // eslint-disable-next-line no-cond-assign
    (s = userAgent.match(/chrome\/([\d.]+)/))
    ? (browser.chrome = s[1])
    : // eslint-disable-next-line no-cond-assign
    (s = userAgent.match(/opera.([\d.]+)/))
    ? (browser.opera = s[1])
    : // eslint-disable-next-line no-cond-assign
    (s = userAgent.match(/version\/([\d.]+).*safari/))
    ? (browser.safari = s[1])
    : 0;
  let version = "";
  if (browser.ie) {
    version = "ie " + browser.ie;
  } else {
    if (browser.firefox) {
      version = "firefox " + browser.firefox;
    } else {
      if (browser.chrome) {
        version = "chrome " + browser.chrome;
      } else {
        if (browser.opera) {
          version = "opera " + browser.opera;
        } else {
          if (browser.safari) {
            version = "safari " + browser.safari;
          } else {
            version = "unknow";
          }
        }
      }
    }
  }
  return version;
}
