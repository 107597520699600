/* eslint-disable */
import uid from '@/boot/uid';
import env from '@/utils/env';
import sessionStorage from '@/utils/sessionStorage';

const client = process.env.VUE_APP_API_CLIENT;
const source = process.env.VUE_APP_API_SOURCE;
const cver = process.env.VUE_APP_API_CVER;

const DEFAULT_PARAMS = {
  client,
  source,
  platform: 'web',
  cver,
  dver: '0',
  iver: '5.32',
  format: 'json'
};

function getCommonParams() {
  return {
    ...DEFAULT_PARAMS,
    uid: uid.uid || '0',
    uuid: uid.uuid || '0',
    systemtime: new Date().getTime()
  };
}

function getParamP(data) {
  let os = env.os;
  let agent = sessionStorage.get('agent');
  if (agent && agent.operatorId) {
    os = os + '_' + agent.operatorId + '_';
  }
  return [data.source, os, data.client, data.cver, data.platform].join(',');
}

export default function(opt) {
  const defaults = getCommonParams();
  const tmc = env.tmc;
  if (tmc) defaults.tmc = tmc;
  const data = {
    ...defaults,
    ...opt.data
  };
  opt.headers = {
    'content-type': 'application/x-www-form-urlencoded',
    ...opt.headers
  };
  data.p = data.appinfo = getParamP(data);
  opt.data = data;
  return opt;
}
