import Vue from "vue";
import {
  Area,
  Button,
  Cell,
  CellGroup,
  Field,
  Form,
  Radio,
  RadioGroup,
  Checkbox,
  Switch,
  Loading,
  Toast,
  Popup,
  Notify,
  Dialog,
  Swiper,
  SwiperItem,
  Calendar,
  IndexBar,
  IndexAnchor,
  DropdownMenu,
  DropdownItem,
  Slider,
  List,
  Icon,
  Tabs,
  Tab,
  Picker,
  Stepper,
  Collapse,
  CollapseItem,
  Overlay,
} from "huoli-ui";
import CLoading from "../components/c-loading";
import CHtml from "../components/c-html";
import CInput from "../components/c-input";
import { getAppInfo } from "@/lib/app";
import haTracker from "@/lib/haTracker";

Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Form);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(Switch);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Notify);
Vue.use(Dialog);
Vue.use(Swiper);
Vue.use(SwiperItem);
Vue.use(Calendar);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Slider);
Vue.use(List);
Vue.use(Icon);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Picker);
Vue.use(Stepper);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Overlay);
Vue.use(Area);

// 原型上的方法
Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue();
Vue.prototype.$withLoading = withLoading;
Vue.prototype.$updateTitle = (title) => {
  document.title = title || getAppInfo().appName;
};

Vue.prototype.$ha = haTracker;

// 全局组件
Vue.component("c-loading", CLoading);
Vue.component("c-html", CHtml);
Vue.component("c-input", CInput);

function withLoading(promise, { message, forbidClick = true } = {}) {
  const toast = Toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick,
    message,
  });
  if (promise.then) {
    return promise.then(
      (r) => {
        toast.clear();
        return r;
      },
      (e) => {
        toast.clear();
        throw e;
      }
    );
  }

  return (...args) => {
    return promise(...args).then(
      (r) => {
        toast.clear();
        return r;
      },
      (e) => {
        toast.clear();
        throw e;
      }
    );
  };
}
