<template>
  <div id="app">
    <keep-alive :include="keepAliveInclude">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import Vue from "vue";
import router from "./router";
// import inobounce from "inobounce";
import env from "@/utils/env";
import goToUrl from "@/utils/goToUrl";
import qs from "qs";
import nativeApi from "@/utils/nativeApi";

export default {
  name: "App",
  data() {
    return {
      keepAliveInclude: [],
    };
  },

  watch: {
    $route(val) {
      this.updateKeepAliveInclude(val);
    },
  },

  beforeCreate() {
    if (env.isNative) {
      nativeApi.invoke("setNavigationBarVisible", {
        value: "0",
        forbiddenanimated: "1",
      });
    }
  },

  created() {
    // XXX: 临时
    const pruneKeepAliveCache = (name) => {
      const idx = this.keepAliveInclude.indexOf(name);
      if (idx >= 0) {
        this.keepAliveInclude.splice(idx, 1);
      }
    };

    Vue.prototype.$pushPage = function (loc, ...args) {
      const matchedComponents = router.getMatchedComponents(loc);
      const component = matchedComponents && matchedComponents[0];

      if (component && component.keepAlive && component.name) {
        pruneKeepAliveCache(component.name);
      }
      // 等待prune更新结束(清理缓存组件)
      this.$nextTick(function () {
        this.$router.push(loc, ...args);
      });
    };
    let myThis = this;
    Vue.prototype.$page = {
      push({ url, name, query, params }) {
        if (!query) query = {};
        query.hlsource = env.hlsource;
        query.analyseSourceEntry = env.hlsource;
        let queryStr = qs.stringify(query);
        queryStr = queryStr.length === 0 ? "" : `?${queryStr}`;

        if (url.substring(0, 4) !== "http") {
          let splitUrl = url.split("?")[0];
          let splitQuery = url.split("?")[1];
          if (typeof splitQuery !== "undefined") {
            queryStr = qs.stringify(
              Object.assign({}, qs.parse(splitQuery), query)
            );
            queryStr = queryStr.length === 0 ? "" : `?${queryStr}`;
          }
          if (env.isNative) {
            if (
              url.substring(0, 10) === "weixinhbgj" ||
              url.substring(0, 4) === "gtgj" ||
              url.substring(0, 4) === "kssl" ||
              url.substring(0, 4) === "hlsl"
            ) {
              goToUrl(`${url}${queryStr}`);
            } else {
              goToUrl(
                `${myThis.$judgeDomain()}/hltrip${splitUrl}${queryStr}`
              );
            }
          } else {
            myThis.$pushPage({
              name,
              query: Object.assign({}, qs.parse(splitQuery), query),
              params,
            });
          }
        } else {
          goToUrl(`${url}${queryStr}`);
        }
      },
      back() {
        if (env.isNative) {
          nativeApi.invoke("close");
        } else {
          myThis.$router.back();
        }
      },
    };
    Vue.prototype.$replacePage = function (loc, ...args) {
      const matchedComponents = router.getMatchedComponents(loc);
      const component = matchedComponents && matchedComponents[0];

      if (component && component.keepAlive && component.name) {
        pruneKeepAliveCache(component.name);
      }

      // 等待prune更新结束(清理缓存组件)
      this.$nextTick(function () {
        this.$router.replace(loc, ...args);
      });
    };
    //判断域名
    Vue.prototype.$judgeDomain=function (){
      let domain = window.location.hostname;
      // 判断当前页面是否为 "haohuimai.com"
      if (domain === "haohuimai.com") {
        return process.env.VUE_APP_BASE_URL_HAO_PAYMENT
      } else {
        return process.env.VUE_APP_BASE_URL_PAYMENT
      }
    }
    this.updateKeepAliveInclude(this.$route);

    // ios 防止顶部底部滚动橡皮筋效果
    // let u = navigator.userAgent;
    // if (u.indexOf("iPhone") > -1) {
    //   inobounce.enable();
    // }
  },

  beforeDestroy() {
    // inobounce.disable();
  },

  methods: {
    updateKeepAliveInclude(route) {
      const matchedComponents = router.getMatchedComponents(route);

      const component = matchedComponents && matchedComponents[0];

      if (component && component.keepAlive && component.name) {
        if (this.keepAliveInclude.indexOf(component.name) < 0) {
          this.keepAliveInclude.push(component.name);
        }
      }
    },
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
  min-height: 100%;
}
</style>
