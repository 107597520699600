import get from "@/utils/get";
import auth from "@/lib/auth";
import rawRequest from "@/utils/raw-request";
import transformers from "./transformers/index";
import addAuthorization from "./transformers/addAuthorization";
import uid from "@/boot/uid";

function request_h5(config) {
  return uid
    .init()
    .then(() => {
      return addAuthorization(config);
    })
    .then((opt) => {
      opt = transformers.h5(opt);
      return rawRequest(opt).then((res) => {
        const body = res.body;
        if (body && body.errcode) {
          throw {
            name: "ResponseError",
            message: body.errmsg,
            data: body,
          };
        } else {
          return body;
        }
      });
    });
}

function request_gateway(transformer, config, retry = false) {
  return addAuthorization(config).then((config) => {
    const opt = transformer(config);
    return rawRequest(opt).then((res) => {
      const body = res.body;
      const code = get(body, "res.hd.code");
      if (code == 1) {
        return body;
      } else {
        if (code == -401 || code == -402 || code == 1009) {
          const Authorization = opt.headers.Authorization;
          if (Authorization && retry) {
            auth
              .refresh({
                invalidate: Authorization,
              })
              .then(() => {
                return request_gateway(transformer, config, false);
              })
              .catch((e) => {
                if (e.name == "AuthError") {
                  return auth.login();
                } else {
                  throw e;
                }
              });
          } else {
            return auth.login();
          }
        }

        let desc = get(body, "res.hd.desc");
        throw {
          name: "ResponseError",
          message: desc,
          data: body,
        };
      }
    });
  });
}

export default {
  h5: request_h5,
  gateway: request_gateway.bind(null, transformers.gateway),
  flight: request_gateway.bind(null, transformers.gateway),
  train: request_gateway.bind(null, transformers.train),
  car: request_gateway.bind(null, transformers.gateway),
  hotel: request_gateway.bind(null, transformers.hotel),
  pay: request_gateway.bind(null, transformers.pay),
  defray: request_gateway.bind(null, transformers.defray),
};
