import auth from "@/lib/auth";

export default function addAuthorization(opt) {
  const setting = opt.auth;

  if (!opt.headers) {
    opt.headers = {};
  }

  if (setting === "no") {
    return Promise.resolve(opt);
  } else if (setting === "yes") {
    return auth.get().then(({ Authorization }) => {
      opt.headers.Authorization = Authorization;
      return opt;
    });
  } else {
    return auth.getOnce().then(
      ({ Authorization }) => {
        opt.headers.Authorization = Authorization;
        return opt;
      },
      () => {
        return opt;
      }
    );
  }
}
