import localStorage from "@/utils/localStorage";
import generateRandomId from "@/utils/random-id";

const exp = {
  uid: null,
  uuid: null,
  init: init,
};
let _i = null;

function init() {
  if (!_i) {
    _i = getUid().then((r) => {
      if (r.uid) {
        exp.uid = r.uid;
        exp.uuid = r.uid;
      }
      // if (r.uuid) exp.uuid = r.uuid;
    });
  }
  return _i;
}
init();

function getUid() {
  let uid = localStorage.get("uid");
  if (!uid) {
    uid = generateRandomId();
    localStorage.set("uid", uid);
  }

  return Promise.resolve({
    uid,
  });
}

export default exp;
