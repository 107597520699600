// 平安薪（平安银行旗下app）
// source: 'pax',
// appname: 'hlsl',
// platform: 'web'

export default {
  hlid: "hlK72GVa",

  // 标题
  pageTitle: "航班管家",

  // 客服电话
  customerServicePhone: "4008005753",
};
