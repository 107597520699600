import env from "@/utils/env";
import auth from "@/lib/auth";
import { getAppInfo } from "@/lib/app";
import get from "@/utils/get";
import trackingParams from "@/config/trackingParams";
import { Toast } from "huoli-ui";
import qs from "@/utils/querystring";
import localStorage from "@/utils/localStorage";

function patchRouter(router) {
  const _push = router.push.bind(router);
  const _replace = router.replace.bind(router);
  router.push = (loc, ...args) => {
    loc = patchLocation(loc);
    _push(loc, ...args);
  };
  router.replace = (loc, ...args) => {
    loc = patchLocation(loc);
    _replace(loc, ...args);
  };
}

// 一些参数始终保持在Query上
function patchLocation(loc) {
  if (typeof loc === "string") {
    loc = {
      path: loc,
    };
  }
  loc.query = {
    ...trackingParams.get(),
    ...loc.query,
  };

  return loc;
}

function authByRouter(router) {
  router.beforeEach((to, from, next) => {
    if (to && to.meta && to.meta.auth) {
      auth.get().then(
        () => next(),
        () => auth.login().then(() => next())
      );
    } else {
      next();
    }
  });
}
function loginbycode(to, from, next) {
  const { authcode, refreshcode } = qs.parse(window.location.search);
  if (authcode) {
    localStorage.set("authcode", authcode);
    localStorage.set("refreshcode", refreshcode);
  }
  next();
}

export default (router) => {
  patchRouter(router);
  authByRouter(router);
  router.beforeEach(loginbycode);
  router.beforeEach(hideAllModal);
  // router.beforeEach(doLoginIfRequired);
  router.afterEach(updateEnv);
  router.afterEach(updateTitle);
  router.afterEach(routechange);
};

function hideAllModal(to, from, next) {
  Toast.clear({
    clearAll: true,
  });
  next();
}

function updateEnv(to) {
  const q = to.query || {};
  if (q.hlsource) env.hlsource = q.hlsource;
  if (q.analyseSourceEntry) env.analyseSourceEntry = q.analyseSourceEntry;
  if (q.tmc) env.tmc = q.tmc;
}

function updateTitle(to) {
  document.title = get(to, "meta.title") || getAppInfo().appName;
}

function routechange() {
  setTimeout(() => {
    if (process.env.NODE_ENV == "production") {
      // google analytics for spa
      // gtag('config', 'G-7KL75X3WZJ', { page_path: window.location.pathname });
      // hl analytics for spa
      // ha('send', 'pageview');
    }
    document.dispatchEvent(new Event("routechange"));
  });
}
