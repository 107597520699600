import md5 from "blueimp-md5";

const API_SID_KEYS = JSON.parse(process.env.VUE_APP_API_SID_KEYS);

export default addSid;

function addSid(opt) {
  if (opt.sidFields && opt.sidFields.length > 0) {
    opt.data.sid = generateSid(opt.sidFields, opt.data);
  }
  return opt;
}

addSid.v3 = function (opt) {
  if (opt.sidFields && opt.sidFields.length > 0) {
    opt.data.sid = generateSidV3(opt.sidFields, opt.data);
  }
  return opt;
};

function generateSid(keys, obj, toUpper = true) {
  let s = keys.map((k) => obj[k]).join("");
  let e = md5(s + process.env.VUE_APP_API_SID_SECRET);
  let sid = API_SID_KEYS.map((i) => e[i]).join("");
  return toUpper ? sid.toUpperCase() : sid;
}

function generateSidV3(keys, obj, toUpper = true) {
  let s = keys.map((k) => obj[k]).join("");
  let e = md5(s + process.env.VUE_APP_API_SID_SECRET);
  let sid = API_SID_KEYS.map((i) => e[i]).join("");
  return toUpper ? sid.toUpperCase() : sid;
}
