<template>
  <div>
    <div v-if="loading" class="loading-dots loading-in">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <!-- TODO: 需要解释为什么是v-show而不用v-else? -->
    <!-- 如果用template v-if 可能会导致slot里面的第一个tag没有打tag，导致找不到对应的css样式，不知道为啥 -->
    <div v-show="!loading">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.loading-dots {
  position: relative;

  &.loading-in {
    height: 100px;
  }

  div {
    position: absolute;
    top: 50px;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #999;
    opacity: 0.1;
  }
}

.loading-dots.loading-in {
  div {
    animation: twinkle 1000ms infinite forwards;
  }
  div:nth-child(1) {
    transform: translate3d(-20px, 0, 0);
  }
  div:nth-child(2) {
    animation-delay: 250ms;
  }
  div:nth-child(3) {
    transform: translate3d(20px, 0, 0);
    animation-delay: 500ms;
  }
}

/* .loading-dots.loading-out { */
/* div { */
/* transform: translate3d(0, -30px, 0); */
/* } */
/* } */

@keyframes twinkle {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
</style>
