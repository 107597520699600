import uid from "@/boot/uid";
import env from "@/utils/env";
import sessionStorage from "@/utils/sessionStorage";
import partner from "@/config/partner";

const hlp_screen = [
  window.outerWidth,
  window.outerHeight,
  window.devicePixelRatio,
].join(",");
const hlp_device = getDevice();

function getDevice() {
  const ua = window.navigator.userAgent;
  if (/iphone|ipod/i.test(ua)) {
    return "iphone";
  } else if (/ipad/i.test(ua)) {
    return "ipad";
  } else {
    return "android";
  }
}

export function getDefaults() {
  let os = env.os;
  let agent = sessionStorage.get("agent");
  if (agent && agent.operatorId) {
    os = os + "_" + agent.operatorId + "_";
  }
  const hlp = {
    operasys: os,
    sysver: "1",
    devicetype: "web",
    device: hlp_device,
    root: "0",
    linkmode: "wifi",
    screen: hlp_screen,
  };

  const partnerConfig = (env.partner && partner[env.partner]) || {};
  // console.log("partnerConfig", partnerConfig);
  // console.log("env.partner", env.partner);
  const defaults = {
    hlid: partnerConfig.hlid || process.env.VUE_APP_API_HLID,
    hlsecret: process.env.VUE_APP_API_HLSECRET,
    projectver: process.env.VUE_APP_API_PVER,
    modulecver: process.env.VUE_APP_API_CVER,
    uid: uid.uid || "0",
    uuid: uid.uuid || "0",
    systemtime: new Date().getTime(),
    hlp: JSON.stringify(hlp),
    tmc: env.tmc,
    userChannel: env.userChannel,
  };

  return defaults;
}
export default function (opt) {
  const defaults = getDefaults();
  // console.log("------getDefaults()", getDefaults());

  opt.headers = {
    "content-type": "application/x-www-form-urlencoded",
    ...opt.headers,
  };
  opt.data = {
    ...defaults,
    ...opt.data,
  };
  return opt;
}
